import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'



const Reset = () => {
    const [ error, setError ] = useState(null)
    const [ data, setData ] = useState({})
    const navigate = useNavigate()

    function handleSubmit(e){
        e.preventDefault()
        axios.post('/api/reset', data)
        .then(res => {
            console.log(res)
            window.flash('success', 'Password updated', 'Please log in')
            navigate('/')

        })
        .catch(err => {
            const message = err.response ? err.response.data : err.message
            window.flash('danger', 'Error', message)
            console.log(err)
            setError(err)
            console.log(err)
        })

    }

    function handleChange(e){
        setData({ ...data, [e.target.name]: e.target.value })
 
    }



    return (
        <div className="page page-center bg-black" style={{ background: 'url(https://wallpaperaccess.com/full/113377.png)', backgroundPosition: 'center'}}>
            <div className="container-tight py-4">
                <div className="text-center mb-4">
                    <a href="/" className="navbar-brand navbar-brand-autodark">
                        <img src="/logos/veeraLogo_dark.png" height="36" alt=""/>
                    </a>
                </div>
                <form className="card card-md" onChange={handleChange} onSubmit={handleSubmit}>
                    <div className="card-body">
                        <div className="card-title text-center mb-4">
                            Reset Password
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input name="email" required type="email" className="form-control" />
                        </div>                    
                        <div className="mb-3">
                            <label className="form-label">Verification Code</label>
                            <input required name="code" type="text" className="form-control" />
                        </div>             
                        <div className="mb-3">
                            <label className="form-label">New Password</label>
                            <input required name="password" type="password" className="form-control" />
                        </div>                      
                        <div className="mb-3">
                            <label className="form-label">Confirm Password</label>
                            <input required name="passwordConfirm" type="password" className="form-control"  />
                        {/* <div className="invalid-feedback">{error ? error.message : ''}</div> */}
                        </div>
                    </div>

                    <div className="card-footer">
                        <button className="btn btn-primary w-100">
                            Reset Password
                        </button>
                        <small className="text-center">Code expired? Request a new one <a href="/forgot">here</a></small>
                    </div>
                </form>
            </div>

        </div>  
    )
}


export default Reset