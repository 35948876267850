import React, { useState } from 'react'
import Select from 'react-select'

const MovePaths = ({ rules, toggleMoveModal, selectedPaths, movePaths }) => {
    const [ targetRule, setTargetRule ] = useState(null)



    const options = rules.map(rule => {
        return {
            label: rule.ruleName,
            value: rule._id
        }
    })

    const num = selectedPaths.length
    return (
        <div className="modal modal-blur fade show" style={{ display: 'block '}}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content" style={{ border: '1px solid grey'}}>
                    <div className="modal-header">
                        <h5 className="modal-title">Move Selected Paths</h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={toggleMoveModal}
                        />
                    </div>

                    <div className="modal-body">
                        <div className="mb-3">
                            <label className="form-label">{`Move ${num} path${num > 1 ? 's' : ''} to rule:`}</label>
                            <Select options={options} onChange={(rule) => setTargetRule(rule.value)} />

                        </div>



                    </div>

                    <div className="modal-footer">
                        <div className="col">
                            <button className="btn" onClick={toggleMoveModal}>Cancel</button>
                        </div>
                        <div className="col-auto">
                            <button 
                                className="btn btn-primary"
                                onClick={() => movePaths(targetRule)}
                            >Move Paths</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}


export default MovePaths