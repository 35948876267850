import React from 'react'

import { IconCheck, IconX, IconTrash, IconEdit } from '@tabler/icons'
const UserTable = ({ users, toggleModal }) => {
    return (
        <div className="container-xl">
            <div className="col-12 mt-3">
                <div className="card mb-3">
                    <div className="card-header">
                        <div className="col">
                            <h3 className="card-title">Users</h3>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table card-table table-vcenter text-nowrap datatable">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Admin</th>
                                        <th className="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map((user, i) =>
                                        <tr key={i}>
                                            <td>{user.name}</td>
                                            <td>{user.email}</td>
                                            <td className="justify-content-center"> 
                                                {user.admin ? <IconCheck color='green' /> : <IconX color='red' />}
                                            </td>
                                            <td>
                                                <div className="d-flex justify-content-around">
                                                    {/* <span href="" 
                                                        className="btn btn-icon" 
                                                        onClick={(e) => toggleModal(e, 'edit', user)}>
                                                        <IconEdit />

                                                    </span>             */}
                                                     <span 
                                                        className="btn btn-icon btn-danger"
                                                        onClick={(e) => toggleModal(e, 'delete', user)}
                                                    >
                                                        <IconTrash />

                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>  
    )
}


export default UserTable