import React, { useState } from 'react'



const AddUser = ({ toggleModal, addUser }) => {
    const [ data, setData ] = useState({})


    function handleChange(e){
        setData({...data, [e.target.name]: e.target.value})
    }

    return (
        <div className="modal modal-blur modal-fade" style={{ display: 'block' }}>
            <form className="modal-dialog modal-dialog-centered" onChange={handleChange} onSubmit={(e) => addUser(e, data)}>
                <div className="modal-content" style={{ border: "1px solid grey" }}>
                    <div className="modal-header">
                        <h5 className="modal-title">
                            Add new user
                        </h5>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label className="form-label">Name</label>
                            <input type="text" name="name" className="form-control"  required />
                        </div>                        
                        <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input type="email" name="email" className="form-control"  required />
                        </div>                        
                        <div className="mb-3">
                            <label className="form-label">Is Admin</label>
                            <div>
                                <label className="form-check form-check-inline">
                                    <input type="radio" name="admin" value="yes"className="form-check-input" required />
                                    <span>Yes</span>
                                </label>          
                                 <label className="form-check form-check-inline">
                                    <input type="radio" name="admin" value="no" className="form-check-input" required />
                                    <span>No</span>
                                </label>
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <div className="col">
                            <button className="btn" onClick={(e) => toggleModal(e, 'add')}>Cancel</button>
                        </div>
                        <div className="col-auto">
                            <button type="submit" className="btn btn-primary">Add</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}



export default AddUser