import React, { useState } from 'react'

import { IconDeviceFloppy, IconX, IconExternalLink, IconLetterCase, IconFileAlert, IconZoomCheck, IconBinary, IconFlag } from '@tabler/icons'
import axios from 'axios'


const RuleEdit = ({ rule, toggleEditMode, getRule }) => {
    const [ changedRule, setChangedRule ] = useState({})


    function handleChange(e){
        setChangedRule({...changedRule, [e.target.name]: e.target.value })
    }


    // useEffect(() => {
    //     setChangedRule(rule)
    // }, [changedRule])

    function handleSubmit(e){
        e.preventDefault()
        axios.post('/api/rule/update', { changedRule, id: rule._id })
        .then(res => {
            toggleEditMode()
            window.flash('success', 'Success', 'Rule updated')
            getRule()
        })
        .catch(err => {
            window.flash('danger', 'Error', err.message)
        })
    }

    return (
        <div className="RuleEdit">
            <div className="page-body">
                <div className="container-xl">
                    <div className="row row-cards">
                        <div className="col-md-12">
                            <form className="card" onChange={handleChange}>
                            <div className="card-status-top bg-warning"></div>

                                <div className="card-header">
                                    <div className="col">
                                        <div className="input-group" style={{ width: '80%'}}>
                                            <div className="input-group-text">Rule Name</div>
                                            <input 
                                                name="ruleName"
                                                className="form-control"
                                                defaultValue={rule.ruleName}
                                            ></input>
                                        </div>
                                    </div>

                                    <div className="col-auto">
                                        <div className="btn-list">
                                            <span className="btn btn-icon" onClick={handleSubmit}>
                                                <IconDeviceFloppy />
                                            </span>
                                            <span className="btn btn-danger btn-icon" onClick={toggleEditMode}>
                                                <IconX />
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body">
                                <div className="row g-3">
                           
                                     <div className="col-9 mb-3">
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <IconExternalLink />
                                            </div>
                                            <div className="col">   
                                                <div className="input-group" style={{ width: '80%'}}>
                                                    <div className="input-group-text">Redirect URL</div>
                                                    <input 
                                                        name="resultredirectURL"
                                                        className="form-control"
                                                        defaultValue={rule.resultredirectURL}
                                                    
                                                    ></input>
                                                </div>
                                
                                     
                                            </div>
                                        </div>
                                    </div>   

                                    <div className="col-3 mb-3">
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <IconFlag />
                                            </div>
                                            <div className="col">   
                                                <div className="text-reset d-block text-truncate small text-muted">
                                                    HOST
                                                </div>
                                                <div className="mt-n1">
                                                    {rule.host || 'n/a' }
                                                </div>
                                            </div>
                                        </div>
                                    </div>                               


                                     <div className="col-3 mb-3">
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <IconFileAlert />
                                            </div>
                                            <div className="col">   
                                                <div className="mt-n1">
                                                    <div className="input-group">
                                                        <div className="input-group-text">Status Code</div>
                                                        <input 
                                                            name="resultstatusCode"
                                                            className="form-control"
                                                            defaultValue={rule.resultstatusCode}
                                                        
                                                        ></input>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>         

                                    <div className="col-3 mb-3">
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <IconZoomCheck />
                                            </div>
                                            <div className="col">   
                                                <div className="text-reset d-block text-truncate text-muted small">
                                                    USE QUERY STRING
                                                </div>
                                                <div className="mt-n1">
                                                    <label htmlFor="" className="form-check form-check-inline">
                                                        <input 
                                                            type="radio" 
                                                            name="resultuseIncomingQueryString" 
                                                            value="1" 
                                                            className="form-check-input" 
                                                            defaultChecked={rule.resultuseIncomingQueryString === '1'}
                                                        />
                                                        <span className="form-check-label">Yes</span>
                                                    </label>                
                                                    <label htmlFor="" className="form-check form-check-inline">
                                                        <input 
                                                            type="radio" 
                                                            name="resultuseIncomingQueryString" 
                                                            value="0"
                                                            className="form-check-input" 
                                                            defaultChecked={rule.resultuseIncomingQueryString === '0'}

                                                         />
                                                        <span className="form-check-label">No</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                        
                                    
                                     <div className="col-3 mb-3">
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <IconLetterCase />
                                            </div>
                                            <div className="col">   
                                                <div className="text-reset d-block.text-truncate text-muted small">
                                                    
                                                    CASE SENSITIVE
                                                </div>
                                                <div className="mt-n1">
                                                    <label htmlFor="" className="form-check form-check-inline">
                                                        <input 
                                                            type="radio" 
                                                            name="caseSensitive" 
                                                            value={true}
                                                            className="form-check-input" 
                                                            defaultChecked={rule.caseSensitive === true}
                                                        />
                                                        <span className="form-check-label">Yes</span>
                                                    </label>                
                                                    <label htmlFor="" className="form-check form-check-inline">
                                                        <input 
                                                            type="radio" 
                                                            name="caseSensitive" 
                                                            value={false}
                                                            className="form-check-input" 
                                                            defaultChecked={rule.caseSensitive === false}
                                                         />
                                                        <span className="form-check-label">No</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                            
                                    
                                     <div className="col-3 mb-3">
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <IconBinary />
                                            </div>
                                            <div className="col">   
                                                <div className="input-group">
                                                    <div className="input-group-text">Regex</div>
                                                    <input 
                                                        name="regex"
                                                        className="form-control"
                                                        defaultValue={rule.regex || 'n/a'}
                                                    
                                                    ></input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                               

                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default RuleEdit