import React, { useState, useEffect } from 'react'
import Bus from '../../Utils/Bus'
const Flash = () => {

    const [ visible, setVisible ] = useState(false)
    const [ message, setMessage ] = useState('')
    const [ type, setType ] = useState('')
    const [ title, setTitle ] = useState('')



    useEffect(() => {
        Bus.addListener('flash', ({ type, title, message }) => {
            setVisible(true)
            setMessage(message)
            setType(type)
            setTitle(title)
            setTimeout(() => {
                setVisible(false)
            },3000)
        })
    
    })

    const styles = {
        position: 'fixed',
        zIndex: '999999',
        width: '100%'
    }

    return (
        visible ?


        <div className={`alert alert-${type} alert-dismissible`} style={styles}>
            <div className="d-flex">
                <div>
                    <div className="alert-title">
                        {title}
                    </div>
                    <div className="text-muted">
                        {message}
                    </div>
                </div>
                    <a className="btn-close" onClick={() => setVisible(false)}></a>
            </div>
        
        </div>
        : 
        null
    )
}


export default Flash