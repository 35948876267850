import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

import { IconPlus } from '@tabler/icons'

import Header from '../Header'
import UserTable from './UserTable'
import AddUser from '../modals/AddUser'
import DeleteUser from '../modals/DeleteUser'

const UserPanel = ({ user, toggleUploadModal }) => {
    const navigate = useNavigate()
    const [ users, setUsers ] = useState([])
    const [ filtered, setFiltered ] = useState([])
    const [ search, setSearch ] = useState('')
    const [ selectedUser, setUser ] = useState({})
    const [ modals, setModals ] = useState({
        edit: false,
        add: false,
        delete: false
    })


    useEffect(() => {
        axios.get('/api/users')
        .then(res => {
            console.log(res.data)
            setUsers(res.data)
            console.log(res.data)
        })
        .catch(err => {
            console.log(err)
        })
        // GET USERS
    },[modals])

    useEffect(() => {
        const regexp = new RegExp(search, 'i')
        setFiltered(users.filter(user => regexp.test(user.name)))
    },[users, search])



    function toggleModal(e, type, user){
        console.log('CLICK', type)
        if (user) setUser(user)
        if (e) e.preventDefault()
        setModals({...modals, [type]: !modals[type] })
    }

    // function editUser(data){
    //     location.pathname.
    // }

    function addUser(e, data){
        e.preventDefault()
        axios.post('/api/users/add',  data)
        .then(res => {
            console.log(res)
            window.flash('success', 'User added', `${data.name} added to users.`)
            toggleModal(null, 'add', null)
        })
        .catch(err => [
            window.flash('danger', 'Error', err.message)

        ])
    }

    function deleteUser(e, user){

        e.preventDefault()
        axios.post('/api/users/delete', user)
        .then(res => {
            window.flash('success', 'User Deleted', `${user.name} successfully deleted.`)
            setUser({})
            toggleModal(null, 'delete', null)
        })
        .catch(err => {
            window.flash('danger', 'Error', err.message)

        })

    }




    // IF USER DOES NOT HAVE ADMIN PRIVALGES, KICK THEM BACK OUT
    if (user.admin === 'no') {
        window.flash('warning', 'Not Allowed', 'You must be an admin to view that page!')
        navigate('/')
        return
    }

    return (
        <div className="page">
            <Header 
                user={user}
                toggleUploadModal={toggleUploadModal}
            />
            {modals.add ? <AddUser toggleModal={toggleModal} addUser={addUser} /> : null}
            {modals.delete ? <DeleteUser toggleModal={toggleModal} deleteUser={deleteUser} user={selectedUser} /> : null}


            <div className="UserPanel page-wrapper">
                <div className="container-xl">
                    <div className="page-header m-2 d-print-none">
                        <div className="row g-2 align-items-center">
                            <div className="col">
                                <div className="page-pretitle">Administrator</div>
                                <h2 className="pagetitle">Manage Users</h2>
                            </div>
                            <div className="col-12 col-md-auto ms-auto d-print-none">
                                <div className="btn-list">
                                    <span className="d-none d-sm-inline">
                                        <input 
                                            type="text" 
                                            className="form-control"  
                                            placeholder='Search users...'
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                     </span>
                                    <button className="btn btn-success" onClick={(e) => toggleModal(e, 'add')}>
                                        <IconPlus />
                                        Add User
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <UserTable
                    users={filtered}
                    toggleModal={toggleModal}
                />

            </div>
        </div>
    )
}

export default UserPanel