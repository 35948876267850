import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

import { IconAlertTriangle } from '@tabler/icons'

const Upload = ({ toggleUploadModal }) => {
    const [ file, setFile ] = useState(null)
    const [fileError, setFileError ] = useState(null)
    const [ uploadErrors, setUploadErrors ] = useState(null)
    const [ uploadPending, setUploadPending ] = useState(false)


    // const handleUploadError = (errors) => {
    //     if (error.info === 'DUP_VANITY_PATHS') {
    //         // Duppaths come in an array of objects, so we need to destructure that to get the duplicates paths
    //         error.items = error.items.map(item => item.paths).join(', ')
    //         setUploadErrors(errors)

    //     } else {
    //         setUploadErrors(error)
    //     }

    // }


    const navigate = useNavigate()
    function handleUpload(e){
        e.preventDefault()
        const formData = new FormData()
        formData.append('file', file)
   

        setUploadPending(true)
        axios.post('/api/upload', formData)
            .then(res => {
                toggleUploadModal()
                window.flash('success', 'Upload Successful', 'Now viewing uploading policy')
                setUploadPending(false)
                navigate(`/${res.data.market}`)

            })
            .catch(err => {
                console.log(err)
                // handleUploadError(err.response.data)
                setUploadErrors(err.response.data)
                setUploadPending(false)

            })
    }

    function handleChange(e){
        const fileType = e.target.files[0].type 
        if (fileType === 'text/csv' || 'application/vnd.ms-excel') {
            setFileError(null)
            setUploadErrors(null)
            setFile(e.target.files[0])
        } else {
            setFileError('Invalid file type.')
        }
    }

    const renderErrorLog = (error) => {
        // const labels = [...new Set(error.map(err => err.errorType))]

        function handleItems(items) {
            if (typeof items === 'object') {
                return (
                    items.map(each =>
                        <>
                            <div className="row mb-2">
                                <div className="col-auto"></div>
                                <div className="col" style={{ textAlign: 'left' }}>
                                    <div className="text-truncate">{each.errorType}</div>
                                    <div className="text-muted">{each.item}</div>
                                </div>
                            </div> 
                        </>
                    )
                )
            } else if (typeof items === 'string') {
                return <p>{items}</p>
            } else {
                return null
            }

            

        }

        return (
            <div className="card-body">
                <IconAlertTriangle
                    size="small"
                    className='icon-lg text-danger'
                />
                <h4>{error.message}</h4>
                {handleItems(error.items)}
            </div>
        )
            //     {error.items ? 
            //         typeof error.items === string ? 
            //         <p>{error.items}</p>
            //         :
            //         error.list ? error.list.map(each =>
            //             <>
            //                 <div className="list-group-item">{each}</div>
            //                     {error.list.filter(err => err.errorType === each).map((error, i) =>
            //                         <div key={i} className="list-group-header">{error.item}</div>
                                
            //                     )}
            //             </>
                 
            //         )
            //         :
            //         null
            //     }
            // </div>
            // )
                                
    }

    return (
        <div
            className="modal modal-blur fade show"
            id="modal-simple"
            tabIndex={-1}
            aria-modal="true"
            style={{ display: "block" }}

            role="dialog"
        >
            <form 
                className="modal-dialog modal-dialog-centered modal-lg" 
                role="document"
                encType='multipart/form-data'
                onChange={handleChange}    
                onSubmit={handleUpload}
            >
                <div 
                    className="modal-content"
                    style={{ border: "1px solid grey" }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title">Upload Policy</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            onClick={(e) => toggleUploadModal(e)}
                            aria-label="Close"
                        />
                    </div>
                    <div className="modal-body">
                        <div className="input-icon">
                            <input 
                                type="file" 
                                className={`form-control ${fileError ? 'is-invalid' : ''}`} 
                                accept="xlsx, .xls, .csv"
                            />
       
                            <div className="invalid-feedback">{fileError || ''}</div>

                        </div>
                        {uploadPending ?
                        <div className="progress">
                            <div className="progress-bar progress-bar-indeterminate bg-primary"></div>
                        </div>
                        : null}

                    </div>
                    <div className="modal-body text-center py-4">
                        {uploadErrors ?
                            renderErrorLog(uploadErrors)
                            :
                            null
                        }
                    {/* {uploadErrors ? uploadErrors.map(error =>
                    <>
                        <div className="row">
                            <div className="col-auto">
                                <IconAlertTriangle
                                    className='icon-lg text-danger'
                                />
                            </div>
                            <div className="col-auto">
                                <div className="text-truncate">{error.errorType}</div>
                                <div className="text-muted">{error.item}</div>
                            </div>
                        </div>
                        </>  )
                        :
                            <div className="text-center">
                                Waiting for file upload
                            </div>
                } */}
                    </div>
      
                
                    <div className="modal-footer">
                        <button type="button" className="btn me-auto" onClick={(e) => toggleUploadModal(e)}>
                        Close
                        </button>
                        <button
                            disabled={uploadPending}
                            type="submit"
                            className="btn btn-primary"

                        >
                        Upload
                        </button>
                    </div>
                </div>
            </form>
        </div>

    )
}



export default Upload