import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Bus from './Utils/Bus';
import Cookies from 'js-cookie'
import axios from 'axios';

import ProtectedRoute from './components/ProtectedRoute'

import Upload from './components/modals/Upload'

import Login from './components/authorisation/Login'
import Forgot from './components/authorisation/Forgot'
import Reset from './components/authorisation/Reset'
import FirstLogin from './components/authorisation/FirstLogin'
import UserPanel from './components/authorisation/UserPanel'
import Flash from './components/modals/Flash'

import Home from './components/Home'
import Market from './components/Market'
import ShowRule from './components/ShowRule'
import UploadPolicy from './components/modals/UploadPolicy';




const App = () => {
  const [ uploadOpen, setUploadOpen ] = useState(false)
  const [ uploadErrors, setUploadErrors ] = useState({})
  const [ authLoading, setAuthLoading ] = useState(true)
  const [ user, setUser ] = useState(null)
  window.flash = (type, title, message) => Bus.emit('flash', ({ type, title, message }))

  function toggleUploadModal(e){
    if (e) e.preventDefault()
    setUploadOpen(!uploadOpen)
  }

  useEffect(() => {
    const token = Cookies.get('veera')
      axios.post('/api/validate', { token })
      .then(res => {
        setUser(res.data.user)
        setAuthLoading(false)
      })
      .catch(err => {
        // window.flash('danger', 'Logged out', err.message)
        setAuthLoading(false)
      })
  },[])

  return (
    <BrowserRouter>
      <div className={uploadOpen ? `modal-open` : null}>
        {uploadOpen ? 
        // <UploadPolicy toggleUploadModal={toggleUploadModal}/>
        <Upload 
          toggleUploadModal={toggleUploadModal}
          setUploadErrors={setUploadErrors} 
        />
        : 
        null}

        <Flash />
        {/* <button className="btn" onClick={handleLogin}>Log in</button>
        <button className="btn" onClick={handleLogout}>Log out</button>
        <Link exact to="/" className="btn">Go to home</Link> */}

        {authLoading ? <div>Loading</div> : 
 
        <Routes>
          <Route path="/login" element={<Login  setUser={setUser}/>} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/first" element={<FirstLogin setUser={setUser} />} />
          

          {/* <Route exact path="/" element={<Home />} /> */}
          <Route 
            exact path="/" 
            element={
              <ProtectedRoute user={user}>
                <Home user={user} toggleUploadModal={toggleUploadModal} />
              </ProtectedRoute>
            } />            
            <Route 
            exact path="/admin" 
            element={
              <ProtectedRoute user={user}>
                <UserPanel user={user} toggleUploadModal={toggleUploadModal} />
              </ProtectedRoute>
            } />        
          <Route 
            exact path="/:locale" 
            element={
              <ProtectedRoute user={user}>
                <Market user={user} toggleUploadModal={toggleUploadModal} />
              </ProtectedRoute>
            } />         
            <Route 
              exact path="/:locale/:rule" 
              element={
                <ProtectedRoute user={user}>
                  <ShowRule user={user} toggleUploadModal={toggleUploadModal} />
                </ProtectedRoute>
            } /> 
        </Routes>
        }
      </div>
    </BrowserRouter>
  )
}

export default App;
 