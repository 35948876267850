import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import axios from 'axios' 

import { IconPlus, IconReplace, IconTrash } from '@tabler/icons'

import AddPaths from './modals/AddPaths'
import MovePaths from './modals/MovePaths'
import PathRow from './PathRow'
import DeletePathModal from './modals/DeletePathModal'



const PathsTable = ({ paths, rule, getRule, rules, market }) => {
    const [ addModalOpen, setAddModalOpen ] = useState(false)
    const [ moveModalOpen, setMoveModalOpen ] = useState(false)
    const [ newPath, setNewPath] = useState('')
    const [ pathError, setPathError] = useState('')
    const [ deletePathConfirmOpen, setDeletePathConfirm ] = useState(false)
    const [ pathToDelete, setPathToDelete ] = useState(null)
    const [ search, setSearch ] = useState('')
    const [ selectedPaths, setSelectedPaths ] = useState([])
    
    const navigate = useNavigate()
    const params = useParams()




    const handleClose = () => {
        setPathToDelete(false)
        setDeletePathConfirm(false)

    }

    const handleOpen = (path) => {

        if (path) setPathToDelete([path])
        setDeletePathConfirm(true)

    }

    function toggleDeletePathConfirm(path){
        if (path) setPathToDelete([path])
        setDeletePathConfirm(!deletePathConfirmOpen)
    }


    function addPath(inputs, e){
        const newPaths = inputs.map(inp => `/${inp.path}`)
        e.preventDefault()
        axios.post(`/api/path/add`, { newPaths, rule })
        .then(res => {
            window.flash('success', 'Success', `${newPath} added to rule`)
            // send alert
            toggleAddModal()
            getRule()
            setPathError(null)

        })
        .catch(err => {
            setPathError({title: err.message, reason: err.response ? err.response.data : 'Unknown error'})
        })
    }


    function deletePath() {
        const p = pathToDelete || selectedPaths
        axios.post(`/api/path/delete`,  { p, rule })
        .then(res => {
            handleClose()
            window.flash('success', 'Success', `Deleted paths from rule`)
            navigate(`/${market.locale}/${rule._id}`)
        })
        .catch(err => {
            window.flash('danger', 'Error', err.message)

        })
    }


    function movePaths(targetRule){
        axios.post(`/api/paths/move`, { selectedPaths, rule, targetRule })
        .then(res => {

            window.flash('success', 'Success', `${selectedPaths.join(', ')} moved.`)
            toggleMoveModal()
            setSelectedPaths([])
            getRule()

        })
        .catch(err => {
            console.log(err)
        })
    }

    function toggleMoveModal(){
        setMoveModalOpen(!moveModalOpen)
    }


    function toggleAddModal(){
        setPathError(null)
        setAddModalOpen(!addModalOpen)
    }


    function handlePathSelect(path){
        selectedPaths.includes(path) 
        ?
        setSelectedPaths(selectedPaths.filter(data => data !== path))
        :
        setSelectedPaths([...selectedPaths, path])
    }

    function selectAll(){
        selectedPaths.length === paths.length ? setSelectedPaths([]) : setSelectedPaths(paths)
    }

    const filteredPaths = () => {
        const regexp = new RegExp(search, 'i')
        return paths.filter(path => regexp.test(path))
    }



    return (
        <div className="container-xl">
            {addModalOpen ? 
                <AddPaths 
                    toggleAddModal={toggleAddModal} 
                    redirectURL={rule.resultredirectURL}
                    host={rule.host}
                    addPath={addPath} 
                    setNewPath={setNewPath}
                    pathError={pathError}
                    currentPath
                /> 
            : null}
            {moveModalOpen ?
                <MovePaths
                    rules={rules}
                    toggleMoveModal={toggleMoveModal}
                    selectedPaths={selectedPaths}
                    movePaths={movePaths}
                />
                :
                null
            }
            {deletePathConfirmOpen 
                ?
                <DeletePathModal 
                    handleClose={handleClose}
                    deletePath={deletePath}
                />
                :
                null
            }
            
            <div className="col-12 mt-3">
                <div className="card mb-3">
                    <div className="card-header">
                        <div className="col">
                            <h3 className="card-title">
                                {paths.length ?
                                 `Showing ${paths.length} Path${paths.length !== 1 ? 's' : ''}` 
                                 : 'This rule currently has no paths.'}
                            </h3>

                        </div>
                        <div className="col-auto">
                            <div className="btn-list">
                                <span className="btn" onClick={toggleAddModal}>
                                    <IconPlus />
                                    Add                         
                                </span>
                                <button 
                                    className="btn" 
                                    onClick={toggleMoveModal}
                                    disabled={!selectedPaths.length}
                                >
                                    <IconReplace />
                                    Move Selected
                                </button>         
                                <button 
                                    className="btn" 
                                    onClick={() => handleOpen()}
                                    disabled={!selectedPaths.length}
                                >
                                    <IconTrash />
                                    Delete Selected
                                </button>
                            </div>
                        </div>            
                     
                    </div>
                    {paths.length ?
                    <div className="card-body border-bottom p7-3">
                        <div className="d-flex">
                            <label className="form-check">
                                <input 
                                    className="form-check-input" 
                                    type="checkbox" 
                                    name="selectAll" 
                                    onChange={selectAll} 
                                    checked={paths.length === selectedPaths.length}

                                />
                                <span className="form-check-label">Select All</span>

                            </label>
                            
                            <div className="ms-auto text-muted">
                                Search: <div className="ms-2 d-inline-block">
                                    <input 
                                        type="text" 
                                        className="form-control form-control-sm" 
                                        placeholder='Search by path name'
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </div>  
                            </div>
                        </div>
                    </div>
                        :
                        null
                        }
                    <div className="table-responsive">
                        <table className="table card-table table-vcenter datatable">
                            {paths.length ?
                            <thead>
                                <tr>
                                <th></th>
                                <th>Path</th>
                                <th className="w-1">Actions</th>
                                <th className="w-1"></th>

                                </tr>
                            </thead>
                            :
                            null}
                            <tbody>

                                {paths ? filteredPaths().map((path, i) => 
                                    <PathRow
                                        path={path}
                                        handleOpen={handleOpen}
                                        rule={rule}
                                        key={i}
                                        getRule={getRule}
                                        selectedPaths={selectedPaths}
                                        handlePathSelect={handlePathSelect}
                                    />
                                )
                                :   null
                                }
                    
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    )
}


export default PathsTable