export function formatData(data){
    const item = data.find(item => item.ruleName.includes('trailing slash'))
    // need a conditional as for two reasons;
    // 1. if the trailing slash row doesn't exist
    // 2. there value will be undefined on first render, even if it does exist
    if (item) {
      const index = data.map(row => row._id).indexOf(item._id)
      if (index > -1) {
        data.splice(index, 1)
        data.push(item)
      }
    }





    let dataForExport = [
      ['ruleName', 'host', 'path', 'query', 'scheme', 'matchURL', 'regex', 'result.useIncomingQueryString', 'result.redirectURL', 'result.statusCode']
  ] 
  
  data.forEach(row => {
    dataForExport.push([
        row.ruleName,
        row.host,
        managePaths(row.path, row.caseSensitive),
        // row.path ? row.path.join(' ') : '',
        row.query || '',
        row.scheme || '',
        row.matchURL || '',
        row.regex || '',
        row.resultuseIncomingQueryString,
        row.resultredirectURL,
        row.resultstatusCode.toString()
    ])
  })
  
  return dataForExport
}



function managePaths(path, cs){
  if (!path) return ''
  path = path.join(' ')
  path = cs ? ":" + path : path
  return path
}

