import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import marketData  from '../assets/markets.json'

import Header from './Header'
import PathsTable from './PathsTable'
import RuleInfo from './RuleInfo'
import RuleEdit from './RuleEdit'
import DeleteRuleModal from './modals/DeleteRuleModal'

const ShowRule = ({ toggleUploadModal, user }) => {
    const [ market, setMarket ] = useState(null)
    const [ rule, setRule ] = useState(null)
    const [ rules, setRules ] = useState(null)
    const [ deleteRuleConfirmOpen, setDeleteRuleConfirm ] = useState(false)
    const [ deletePathConfirmOpen, setDeletePathConfirm ] = useState(false)
    const [ pathToDelete, setPathToDelete ] = useState('')
    const [ editMode, setEditMode ] = useState(false)
    const params = useParams()
    const navigate = useNavigate()


    function toggleEditMode(){
        setEditMode(!editMode)
    }

    function getRules(){
        axios.get(`/api/rules/${params.locale}`)
        .then(res => {
            setRules(res.data)
        })
        .catch(err => {
            window.flash('danger', 'Error', err.message)
        })
    }
    


    function deleteRule(){
        axios.post(`/api/rule/delete`, { rule })
        .then(res => {
            toggleDeleteRuleConfirm()
            window.flash('success', 'Success', `"${rule.ruleName}" deleted.`)
            navigate(`/${market.locale}`)
        })
        .catch(err => {
            window.flash('danger', 'Error', err.message)
        })
    }

    function toggleDeleteRuleConfirm(){    
        setDeleteRuleConfirm(!deleteRuleConfirmOpen)
    }   
    
    function toggleDeletePathConfirm(path){
        if (path) setPathToDelete(path)
        setDeletePathConfirm(!deletePathConfirmOpen)
    }

    function getRule(){
        axios.get(`/api/rule/?id=${params.rule}`, {id: params.rule})
        .then(res => {
            setRule(res.data)
        })
        .catch(err => {
            window.flash('danger', 'Error', err.message)
        })
    }


    useEffect(() => {
        setMarket(marketData.filter(market => market.locale === params.locale)[0])
        getRule()
        getRules()
    }, [params])


    if (!rule) return null
    const flag = market.locale.split('_')[1]

    return (
        <div className="page">
            <Header
                user={user}
                toggleUploadModal={toggleUploadModal}
            />
            <div className="page-wrapper">
            {deleteRuleConfirmOpen 
                ?
                <DeleteRuleModal 
                    toggleDeleteRuleConfirm={toggleDeleteRuleConfirm}
                    deleteRule={deleteRule}
                />
                :
                null
            }            

                <div className="container-xl">

                <div className="page-header m-2 d-print-none">
                        <div className="row g-2 align-items-center">
                            <div className="col-auto">
                                <div className={`flag flag-md flag-country-${flag.toLowerCase()}`}></div>
                            </div>
                            <div className="col">
                                <div className="page-pretitle">{market.host}</div>
                                <h2 className="pageTitle">{market.name}</h2>
                            </div>
                            <div className="col-12 col-md-auto ms-auto d-print-none">
                                <ol className="breadcrumb breadcrumb-arrows" aria-label="breadcrumbs">
                                    <li className="breadcrumb-item">
                                        <a href="/">Home</a>
                                    </li>        
                                    <li className="breadcrumb-item">
                                        <a href={`/${market.locale}`}>{market.name}</a>
                                    </li>         
                                     <li className="breadcrumb-item active">
                                        <a href={`/${market.locale}`}>{`#${rule._id}`}</a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>


                {editMode ? 
                    <RuleEdit
                        rule={rule}
                        toggleEditMode={toggleEditMode}

                        getRule={getRule}
                    /> 
                :
                    <RuleInfo 
                        rule={rule}
                        toggleDeleteRuleConfirm={toggleDeleteRuleConfirm}
                        toggleEditMode={toggleEditMode}
                        setEditMode={setEditMode}
                        editMode={editMode}
                    />
                }
  
                <PathsTable
                    market={market}
                    rule={rule}
                    rules={rules}
                    paths={rule.path}
                    toggleDeletePathConfirm={toggleDeletePathConfirm}
                    // deletePath={deletePath}
                    getRule={getRule}

                />

            </div>

        </div>
    )
}


export default ShowRule