import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import axios from 'axios'
import {  useNavigate } from 'react-router-dom'




const Login = ({ user, setUser }) => {
    const [data, setData] = useState({
        email: '',
        password: ''
    })
    const [error, setError] = useState(null)

    const navigate = useNavigate()


    useEffect(() => {
        if (user) navigate('/')
    },[])

    function handleChange(e) {
        setError(null)
        setData({...data, [e.target.name]: e.target.value })
    }

    function handleSubmit(e) {
        e.preventDefault()
        axios.post('/api/login', data)
        .then(res => {

            const { token, user } = res.data
            Cookies.set('veera', token)
            setError(null)
            setUser(user)
            navigate('/')
        })
        .catch(err => {
            console.log(err.response)
            if (err.response.data === 'New password is required.') {
                window.flash('warning', 'Update Password', 'You must update your password to continue')
                navigate('/first')
                return
            }

            setError(err.response.data)
        })
        // send request to backend. if user is valid, set cookie

        // if not authorised, flash error and reload login page
    }


    if (user) {
        navigate('/')

    } else {
        return(
            <div className="Login page page-center bg-black" style={{ background: 'url(https://wallpaperaccess.com/full/113377.png)', backgroundPosition: 'center'}}>
                <div className="container-tight py-4">
                    <div className="text-center mb-4">
                    <a href="/" className="navbar-brand navbar-brand-autodark">
                        <img src="/logos/veeraLogo_dark.png" height="36" alt=""/>
                    </a>
                    </div>
                    <form className="card card-md" onSubmit={handleSubmit} onChange={handleChange} >
                    <div className="card-body">
                        <h2 className="card-title text-center mb-4">Login to your account</h2>
                        <div className="mb-3">
                        <label className="form-label">Email address</label>
                        <input required type="email" name="email" className={`form-control ${error ? 'is-invalid' : ''}`} placeholder="Enter email" />
                        </div>
                        <div className="mb-2">
                            <label className="form-label">
                                Password
                                <span className="form-label-description">
                                    <a href="/forgot">Forgotten password</a>
                                </span>
                            </label>
                            <input required type="password" name="password" className={`form-control ${error ? 'is-invalid' : ''}`} placeholder="Password" />
                            <div className="invalid-feedback">{error ? error.message : ''}</div>

                        </div>
        
                        <div className="form-footer">
                        <button type="submit" className="btn btn-primary w-100">Sign in</button>
                        </div>
                    </div>

                    </form>

                </div>

            </div>
        )
    }
}


export default Login