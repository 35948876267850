import React, { useState } from 'react'
 
import { IconAlertTriangle, IconPlus, IconTrash } from '@tabler/icons'


const AddPaths = ({ redirectURL, host, toggleAddModal, addPath, setNewPath, pathError }) => {
    
        const randomID = () => Math.random().toString(36).slice(2, 7);
        const [ inputs, setInputs ] = useState([
        { path: '', loop: false, key: randomID() }
    ])



    
    const addInput = (e) => {
        setInputs([...inputs, { path: '', loop: false, key: randomID() }])
    }

    const removeInput = (i) => {
        const newInputs = [...inputs]
        newInputs.splice(i, 1)
        setInputs(newInputs)
    }



    const handleChange = (i, e) => {
        // 1. update the value of the item in the array
        // 2. check for loop issues and set accordingly

        const redirectPath = redirectURL.split(`${host}/`)[1]

        const copy = [...inputs]
        let obj = {
            path: e.target.value,
            key: copy[i].key
        }

        if (e.target.value && e.target.value === redirectPath) {
            obj.loop = true
        } else {
            obj.loop = false
        }

        copy[i] = obj

        setInputs(copy)

    }



    return (
        <div
            className="modal modal-blur fade show"
         
            style={{ display: "block" }}
        >
            <form className="modal-dialog modal-dialog-centered" onSubmit={(e) => addPath(inputs, e)}>
                <div className="modal-content" style={{ border: "1px solid grey" }}>
                    <div className="modal-header">
                        <h5 className="modal-title">Add a vanity path</h5>
                        <button
                        type="button"
                        className="btn-close"
                        onClick={toggleAddModal}
                        />
                    </div>
                    <div className="modal-body">
                        <div className="row mb-3 align-items-end">
         
                        <div className="col">
                            <label className="form-label">Paths - <small>The '/' will be added automatically</small></label>
                                {inputs.map((inp, i) =>
                                    <div key={inp.key} className="input-group col mb-2">
                                        <button 
                                            className="btn btn-icon me-1" 
                                            type="button" 
                                            onClick={() => removeInput(i)} 
                                            disabled={i === 0 && inputs.length === 1}
                                        ><IconTrash /></button>
                                        <div className="input-group-text">/</div>
                                            <input 
                                                // style={{ maxWidth: '80%'}}
                                                key={inp.key}
                                                type="text" 
                                                name="path"
                                                className={`form-control me-2 ${inp.loop ? 'is-invalid' : ''}`}
                                                onChange={(e) => handleChange(i, e)}
                                                />
                                            {inp.loop ? <div className="invalid-feedback">This path will create a redirect loop</div> : null}

                                    </div>
                            
                                )}
                                
                        <div className="row">
                            <div className="col-auto">
                            <button className="btn btn-icon" onClick={addInput} type="button" style={{textAlign: 'center'}}>
                                <IconPlus  />
                            </button>

                            </div>
                                

                            
                        </div>
                            
                        </div>
                        </div>
                    </div>
                    {pathError ?
                    <div className="modal-body text-center py-4">
                        <IconAlertTriangle
                            className='icon-lg text-danger'
                        />
                        <h3>{pathError.title || 'Unknown Error'}</h3>
                        <div className="text-muted">{pathError.reason}</div>
            
                     </div>
                    : null                
                    }
                    <div className="modal-footer">
                        <button type="submit" className="btn me-auto" onClick={toggleAddModal}>
                        Close
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary"
                        >
                        Add Path
                        </button>
                    </div>
                </div>
            </form>
        </div>

    )
}


export default AddPaths