import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import markets from '../assets/markets.json'
import Cookies from 'js-cookie'

import { IconHome, IconMapPins, IconUpload, IconUsers } from '@tabler/icons'

const Header = ({ toggleUploadModal, user }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const name = user.name.split(' ')
    if (location.pathname === '/login') return null


    function logout(){
      Cookies.remove('veera')
      navigate('/login')
    }


    return (
      <aside className="navbar navbar-vertical navbar-expand-lg navbar-dark">

        <div className="container-fluid">

          <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
              <a href="/">
                <img src="/logos/veeraLogo_dark.png" alt="Veera" className="navbar-brand-image" />
              </a>
          </h1>

          <div className="collapse navbar-collapse">
            <ul className="navbar-nav pt-lg-3">
              <li className="nav-item">
                <a href="/" className="nav-link">
                <span className="nav-link-icon d-md-none d-lg-inline-block  ">
                  <IconHome />
                </span>
                <span className="nav-link-title">Home</span>
                </a>
              </li>    
              <li className="nav-item">
                <a href="#" className="nav-link" onClick={(e) => toggleUploadModal(e)}>
                <span className="nav-link-icon d-md-none d-lg-inline-block  ">
                  <IconUpload />
                </span>
                <span className="nav-link-title">Upload Policy</span>
                </a>
              </li>
              {user.admin === 'yes' ?
              <li className="nav-item">
                <a href="/admin" className="nav-link">
                <span className="nav-link-icon d-md-none d-lg-inline-block  ">
                  <IconUsers />
                </span>
                <span className="nav-link-title">Manage Users</span>
                </a>
            </li>
              : null
              }    


              <li className="nav-item active dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#navbar-layout"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="false"
                  role="button"
                  aria-expanded="false"
                >
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                    <IconMapPins />
                </span>
                <span className="nav-link-title">Markets</span>
              </a>
              <div className="dropdown-menu">
                <div className="dropdown-menu-columns">
                  <div className="dropdown-menu-column mb-3">
                    {markets.map((market, i) => 
                        <a key={i} href={`/${market.locale}`} className="dropdown-item">
                          {market.name}
                        </a>
                    )} 
                  </div>
                </div>
              </div>
            </li>
          </ul>


            {/* USER SECTION */}
            {user ? 
            
            <div className="my-2 my-md-0 flex-grow-1 flex-md-grow-0 order-first order-md-last">
  
              <div className="nav-item dropdown mb-3">
                  <a
                    href="#"
                    className="nav-link d-flex lh-1 text-reset p-0"
                    data-bs-toggle="dropdown"
                    aria-label="Open user menu"
                  >
                    <span
                      className="avatar avatar-sm"
                      style={{ backgroundImage: `url(https://ui-avatars.com/?name=${name[0]}+${name[1]})` }}
                    />
                    <div className="d-none d-xl-block ps-2">
                      <div>{user.name}</div>
                      <div className="mt-1 small text-muted">{user.email}</div>
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <button href="#" className="dropdown-item btn" onClick={logout}>
                      Logout
                    </button>
                  </div>
                </div>

            </div>
            : null }
 

          </div>


        </div>
      </aside>



    )
}


export default Header