import React, { useState, useEffect } from 'react'
import { IconFileExport, IconFilePlus } from '@tabler/icons'
import { CSVLink } from 'react-csv'
import { v4 as uuid } from 'uuid'
import axios from 'axios'

import AddRule from './modals/AddRule'


const RulesTable = ({ market, rules, getRules, exportData }) => {
    const [ search, setSearch ] = useState('')
    const [ addRuleOpen, setAddRuleOpen ] = useState(false)
    const [ newRule, setNewRule ] = useState(null)
    const [filterOption, setFilterOption ] = useState('ruleName')

    const filtered = () => {
        const regexp = new RegExp(search, 'i')
        if (filterOption === 'paths') {
            return rules.filter(rule => regexp.test(rule.path.join(' ')))
        }
        return rules.filter(rule => regexp.test(rule[filterOption]))
    }

    const filterOpts = [
        {
            label: 'Rule Name',
            value: 'ruleName'
        },
        {
            label: 'Redirect URL',
            value: 'resultredirectURL'
        },
        {
            label: 'Path Name',
            value: 'paths'
        },
    ]


    function handleChange(e){
        setNewRule({...newRule, [e.target.name]: e.target.value})
    }


    function addRule(e){
        e.preventDefault()
        axios.post(`/api/rules/add`, { market, newRule })
        .then(res => {
            window.flash('success', 'New Rule Created', `"${newRule.ruleName} successfully added to policy`)
            toggleAddRuleModal()
            getRules()
        })
        .catch(err => {
            window.flash('danger', 'Error', err.message)
        })
    }


    function toggleAddRuleModal(){
        setAddRuleOpen(!addRuleOpen)
    }


    return (
        <div className="container-xl">
            {
                addRuleOpen ?
                    <AddRule
                        toggleAddRuleModal={toggleAddRuleModal}
                        addRule={addRule}
                        handleChange={handleChange}
                    />
                :
                    null
            }
            <div className="col-12 mt-3">
                <div className="card mb-3">
                    <div className="card-header">
                        <div className="col">
                            <h3 className="card-title">Rules</h3>

                        </div>
                        <div className="col-auto">
                            <div className="btn-list">
                                <span className="btn btn-primary" onClick={toggleAddRuleModal}> 
                                        <IconFilePlus />
                                        Create New Rule
                                    </span>
                                
                                <CSVLink
                                    data={exportData || ''}
                                    target="_blank"
                                    filename={`${uuid()}.csv`}
                                >
                                    <span className="btn btn-warning"> 
                                        <IconFileExport />
                                        Export Policy
                                    </span>
                                </CSVLink>
                                
                            </div>
                
                        </div>

                    </div>

                    <div className="d-flex m-3 align-items-center">
                        <div className="text-muted">
                            Showing {filtered().length} entr{filtered().length === 1 ? 'y' : 'ies'}
                        </div>
                        <div className="ms-auto">
                            <div className="d-flex">
                            <select 
                                style={{ marginRight: 2}}
                                className="form-select"
                                onChange={(e) => {setFilterOption(e.target.value)}}
                            >
                                {filterOpts.map((opt, i) =>
                                    <option key={i} value={opt.value}>{opt.label}</option>
                                )}
                                        Filter Option
                            </select>
                            <input
                                type="text" 
                                className="form-control form-control-sm ml2" 
                                placeholder='Search...'
                                onChange={(e) => setSearch(e.target.value)}
                            />

                            </div>
                        </div>

                    </div>
                    
   
                    <div className="table-responsive">
                        <table className="table card-table table-vcenter text-nowrap datatable">
                            <thead>
                                <tr>
                                   <th>Rulename</th>
                                   {/* <th>Redirect URL</th> */}
                                   <th className="w-1">Paths</th>
                                   <th className="w-1">Actions</th>

                                </tr>
                            </thead>
                            <tbody>
                                {filtered().map((rule, i) => 
                                    <tr key={i}>
                                        <td>
                                            {rule.ruleName}
                                        </td>          
                                         {/* <td className="text-truncate"> 
                                            {rule.resultredirectURL} 
                                        </td>        */}
                                        <td className="w-1">
                                            {rule.path ? rule.path.length : 0}
                                        </td>
                                        <td className="w-1">
                                            <a href={`/${market.locale}/${rule._id}`} className="btn btn-primary">View</a>
                                        </td>
                                    </tr>
                                )}
                    
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    )
}


export default RulesTable