import React, { useState, useEffect } from 'react'
import axios from 'axios'
import marketData from '../assets/markets.json'

import { formatData } from '../helpers'

import Header from './Header'
import { useNavigate, useParams } from 'react-router-dom'


import RulesTable from './RulesTable'


const Market = ({ toggleUploadModal, user }) => {
    const params = useParams()
    const navigate = useNavigate()
    const [ market, setMarket ] = useState(null)
    const [ rules, setRules ] = useState([])
    const [ exportData, setExportData ] = useState(null)

    function getRules(){
        axios.get(`/api/rules/${params.locale}`)
        .then(res => {
            setRules(res.data)
        })
        .catch(err => {
            window.flash('danger', 'Error', err.message)
        })
    }

    useEffect(() => {
        const match = marketData.find(market => market.locale === params.locale)

        if (!match) {
         navigate('/')
         window.flash('danger', 'Error', 'No market found, returned to homepage')   
         return
        }
        setMarket(marketData.filter(market => market.locale === params.locale)[0])
        getRules()
 
    },[])

    useEffect(() => {
        setExportData(formatData(rules))
    },[rules])


    if (!market) return
    if (!rules) return
    const flag = market.locale.split('_')[1]
    return (
        <div className="page">
         <Header
            user={user}
            toggleUploadModal={toggleUploadModal}
        />

            <div className="Market page-wrapper">
                <div className="container-xl">
                    <div className="page-header m-2 d-print-none">
                        <div className="row g-2 align-items-center">
                            <div className="col-auto">
                                <div className={`flag flag-md flag-country-${flag.toLowerCase()}`}></div>
                            </div>
                            <div className="col">
                                <div className="page-pretitle">{market.host}</div>
                                <h2 className="pageTitle">{market.name}</h2>
                            </div>
                            <div className="col-12 col-md-auto ms-auto d-print-none">
                                <ol className="breadcrumb breadcrumb-arrows" aria-label="breadcrumbs">
                                    <li className="breadcrumb-item">
                                        <a href="/">Home</a>
                                    </li>         <li className="breadcrumb-item active">
                                        <a href={`/${market.locale}`}>{market.name}</a>
                                    </li>
                                </ol>

                            </div>
                        </div>
                    </div>
                </div>
                           {/* RULES TABLE NEEDS TO BE HERE */}
            <RulesTable
                market={market}
                rules={rules}
                getRules={getRules}
                exportData={exportData}
            />
            </div>
        </div>
    )
}


export default Market