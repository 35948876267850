import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const Forgot = () => {
    const navigate = useNavigate()
    const [ error, setError ] = useState(null)
    const [ email, setEmail ] = useState('')


    function handleSubmit(e){
        e.preventDefault()
        axios.post('/api/forgot', { email }, { "content-type" : "application/x-www-form-urlencoded"})
        .then(res => {
            // flash
            window.flash('success', 'Code Sent', 'Please check your email for you verification code')
            navigate('/reset')


        })
        .catch(err => {
            window.flash('danger', 'Error', err.message)
        })
    }

    return (
        <div className="Login page page-center bg-black" style={{ background: 'url(https://wallpaperaccess.com/full/113377.png)', backgroundPosition: 'center'}}>
            <div className="container-tight py-4">
                <div className="text-center mb-4">
                <a href="/" className="navbar-brand navbar-brand-autodark">
                    <img src="/logos/veeraLogo_dark.png" height="36" alt=""/>
                </a>
                </div>
                <form className="card card-md" onSubmit={handleSubmit} onChange={(e) => setEmail(e.target.value)} >
                    <div className="card-body">
                        <h2 className="card-title text-center mb-4">Forgotten password</h2>
                        <div className="mb-3">
                            <label className="form-label">Email address</label>
                            <input required type="email" name="email" className={`form-control ${error ? 'is-invalid' : ''}`} placeholder="Enter email" />
                        </div>
       
        
                        <div className="form-footer">
                        <button type="submit" className="btn btn-primary w-100">Send password reset</button>
                        </div>
                        <small className="small">Already have a code? Go to <a href="/reset">reset</a> password</small>
                    </div>

                </form>

            </div>

        </div>  
    )
}

export default Forgot