import React from 'react'



const AddRule = ({ toggleAddRuleModal, handleChange, addRule }) => {
    return (
        <div className="modal modal-blur fade show" style={{ display: 'block'}}>
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <form 
                    className="modal-content" 
                    style={{ border: '1px solid grey'}} 
                    onChange={(e) => handleChange(e)} 
                    onSubmit={(e) => addRule(e)}
                >
                    <div className="modal-header">
                        <h5 className="modal-title">
                            Creating New Rule
                        </h5>
                        <button className="btn-close" onClick={toggleAddRuleModal}></button>
                    </div>

                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="" className="form-label">Rule Name</label>
                            <input 
                                required 
                                name="ruleName"
                                type="text" 
                                className="form-control" 
                            />
                        </div>                   
                        <div className="mb-3">
                            <label htmlFor="" className="form-label">Redirect URL</label>
                            <input 
                                required 
                                type="text" 
                                className="form-control" 
                                name="resultredirectURL"

                            />
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="" className="form-label">Regex</label>
                                    <input name="regex" type="text" className="form-control" />
                                </div>        
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="" className="form-label">Status Code</label>
                                    <select required className="form-select" name="resultstatusCode">
                                        <option value="" disabled selected>Please Choose</option>
                                        <option value="301">301</option>  
                                        <option value="302">302</option>  
                                        <option value="303">303</option>  
                                        <option value="304">304</option>  
                                        <option value="305">305</option>  
                                        <option value="306">306</option>  
                                        <option value="307">307</option>  
                                    </select>
                                </div>
                            </div>          
           
                        </div>       
                        
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label className="form-label">Use Query String</label>
                                    <div>
                                        <label className="form-check form-check-inline">
                                            <input 
                                                name="resultuseIncomingQueryString"
                                                value="1"
                                                type="radio" 
                                                className="form-check-input" 
                                                required
                                            />
                                            <span className="form-check-label">Yes</span>
                                        </label>         
                                        <label className="form-check form-check-inline">
                                            <input 
                                                name="resultuseIncomingQueryString"
                                                type="radio" 
                                                value="0"
                                                className="form-check-input" 
                                                required
                                            />
                                            <span className="form-check-label">No</span>
                                        </label>
                                    </div>
                                </div>        
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label className="form-label">Case Sensitive</label>
                                    <div>
                                        <label className="form-check form-check-inline">
                                            <input 
                                                name="caseSensitive"
                                                type="radio" 
                                                value={true}
                                                className="form-check-input" 
                                                required
                                            />
                                            <span className="form-check-label">Yes</span>
                                        </label>         
                                        <label className="form-check form-check-inline">
                                            <input 
                                                name="caseSensitive"
                                                type="radio" 
                                                value={false}
                                                className="form-check-input" 
                                                required
                                            />
                                            <span className="form-check-label">No</span>
                                        </label>
                                    </div>
                                </div>     
                            </div>          
           
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="col">
                            <button className="btn" onClick={toggleAddRuleModal}>Cancel</button>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-primary">Add Rule</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}


export default AddRule