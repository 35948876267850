import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

import axios from 'axios'

const FirstLogin = ({ user, setUser }) => {
    const [ data, setData ] = useState({})
    const navigate = useNavigate()



    function handleSubmit(e){
        e.preventDefault()
        if (data.newPassword !== data.passwordConfirm) {
            window.flash('danger', 'Error', 'Passwords do not match')
            return
        }
        axios.post('/api/login', data)
            .then(res => {
                const { token, user } = res.data
                Cookies.set('veera', token)
                setUser(user)
                navigate('/')
            })
            .catch(err => {
                window.flash('danger', 'Error', err.message)

            })

    }

    function handleChange(e){
        setData({...data, [e.target.name]: e.target.value })
    }





    return (
        <div className="page page-center bg-black" style={{ background: 'url(https://wallpaperaccess.com/full/113377.png)', backgroundPosition: 'center'}}>
            <div className="container-tight py-4">
                <div className="text-center mb-4">
                    <a href="/" className="navbar-brand navbar-brand-autodark">
                        <img src="/logos/veeraLogo_dark.png" height="36" alt=""/>
                    </a>
                </div>
                <form className="card card-md" onChange={handleChange} onSubmit={handleSubmit}>
                    <div className="card-body">
                        <div className="card-title text-center mb-4">
                            Reset Password
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input name="email" required type="email" className="form-control" />
                        </div>                    
                        <div className="mb-3">
                            <label className="form-label">Temporary Password</label>
                            <input required name="password" type="password" className="form-control" />
                        </div>             
                        <div className="mb-3">
                            <label className="form-label">New Password</label>
                            <input required name="newPassword" type="password" className="form-control" />
                        </div>                      
                        <div className="mb-3">
                            <label className="form-label">Confirm Password</label>
                            <input required name="passwordConfirm" type="password" className="form-control"  />
                        {/* <div className="invalid-feedback">{error ? error.message : ''}</div> */}
                        </div>
                    </div>

                    <div className="card-footer">
                        <button className="btn btn-primary w-100">
                            Reset Password
                        </button>
                        <small className="text-center">Didn't receive a temporary password? Contact an admin</small>
                    </div>
                </form>
            </div>

        </div>
    )
}



export default FirstLogin