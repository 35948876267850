import React from 'react'
import { useState, useEffect } from 'react'
import marketData from '../assets/markets.json'



import { IconUpload } from '@tabler/icons'

import Header from './Header'


const Home = ({ toggleUploadModal, user }) => {

    console.log(user)

    const [ markets, setMarkets] = useState(null)
    const [ search, setSearch ] = useState('')


    useEffect(() => {
        setMarkets(marketData)
    })


    const handleChange = (e) => setSearch(e.target.value)
    

    const filteredMarkets = (e) => {
        const regexp = new RegExp(search, 'i')
        return markets.filter(market => regexp.test(market.name))
    }

    function renderMarketCard(market, i){
        const code = market.locale.split('_')[1]
        return (
            <div className="col-md-6 col-lg-3"  key={i}>
                <a className="card card-sm card-link" style={{cursor: 'pointer'}} href={`/${market.locale}`}>
                    <div className="card-body">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <span className={`flag flag-md flag-country-${code.toLowerCase()} me-1`}></span>
                            </div>
                        <div className="col">
                            <div className="font-weight-medium">{market.name}</div>
                            <div className="text-muted">{market.host}</div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        )
    }

    if (!markets) return null
    return (
        
        <div className="page">
        <Header
            user={user}
            toggleUploadModal={toggleUploadModal}
        />

        <div className="Home page-wrapper">
        <div className="container-xl">
            <div className="page-header m-2 d-print-none">
                <div className="row g-2 align-items-center">
                    <div className="col">
                        <div className="page-pretitle">Welcome to Veera</div>
                        <h2 className="pageTitle">Select Market</h2>
                    </div>
                    <div className="col-12 col-md-auto ms-auto d-print-none">
                        <div className="btn-list">
                            <span className="d-none d-sm-inline">
                                <input 
                                    type="text" 
                                    className="form-control"  
                                    placeholder='Search markets...'
                                    onChange={handleChange}
                                />
                            </span>
                            <button href="" className="btn btn-primary" onClick={() => toggleUploadModal()}>
                                <IconUpload />
                                Upload Policy File
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div className="container-xl">
            <div className="row row-deck row-cards">
                {filteredMarkets().map((market, i) => 
                    renderMarketCard(market, i)
                )}

            </div>
        </div>

    </div>
    </div>
    )
}



export default Home