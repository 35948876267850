import React from 'react'

import { IconAlertTriangle } from '@tabler/icons'

const DeleteRuleModal = ({ toggleDeleteRuleConfirm, deleteRule }) => {
    return (
        <div
            className="modal modal-blur fade show"
            id="modal-danger"
            tabIndex={-1}
            role="dialog"
            aria-modal="true"
            style={{ display: "block" }}
        >
            <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                <div className="modal-content">
                    <button
                        type="button"
                        className="btn-close"
                        onClick={toggleDeleteRuleConfirm}
                 
                    />
                    <div className="modal-status bg-danger" />
                    <div className="modal-body text-center py-4">
                        <IconAlertTriangle
                            className="icon-lg text-danger"
                        />
                        <h3>Are you sure?</h3>
                        {/* <div className="text-muted">
                        Do you really want to remove 84 files? What you've done cannot be
                        undone.
                        </div> */}
                    </div>
                    <div className="modal-footer">
                        <div className="w-100">
                            <div className="row">
                                <div className="col">
                                    <span href="#" className="btn w-100" onClick={toggleDeleteRuleConfirm}>
                                        Cancel
                                    </span>
                                </div>
                                <div className="col">
                                    <span
                                        onClick={deleteRule}
                                        className="btn btn-danger w-100"
                                    >
                                        Yes, delete
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default DeleteRuleModal