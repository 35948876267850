import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { IconEdit, IconTrash, IconDeviceFloppy, IconX } from '@tabler/icons'

const PathRow = ({ path, handleOpen, rule, getRule, selectedPaths, handlePathSelect }) => {
    const [ editing, setEditing ] = useState(false)
    const [ updatedPath, setUpdatedPath ] = useState('')
    const [ loopErr, setLoopErr ] = useState(false)

    useEffect(() => {
        const { resultredirectURL, host } = rule
        const redirectPath = resultredirectURL.split(`${host}/`)[1]
        if (updatedPath === `/${redirectPath}`) {
            setLoopErr(true)
        } else {
            setLoopErr(false)
        }
    },[updatedPath])


    function updatePath(newPaths, path, rule){
        if (!newPaths) return
        // UPDATED PATH NEEDS TO BE CALLED 'NEWPATH' SO I CAN RUN THE DUPLICATE CHECK MIDDLEWARE FUNCTION
        axios.post(`/api/path/update`, { newPaths: [newPaths], path, rule })
        .then(res => {
            window.flash('success', 'Success', `"${path}" updated to "${newPaths}"`)
            setEditing(!editing)
            getRule()
        })
        .catch(err => {
            window.flash('danger', 'Error', err.response ? err.response.data : err.message)
            // setUpdateError(err.response.data ? err.response.data.message : err.message)
        })
    }


    return (
        <tr className={editing ? `pathInEdit` : ''}>
            <td>
                <input 
                    type="checkbox" 
                    className="form-check-input" 
                    disabled={editing}    
                    onChange={() => handlePathSelect(path)}
                    checked={selectedPaths.includes(path)}
                />
            </td>          
            <td>
                {editing ? 
                <div className="input-group">
                    <div className="input-group-text">/</div>
                    <input 

                        style={{ width: '75%' }}
                        onChange={(e) => setUpdatedPath(`/${e.target.value}`)}
                        className={`form-control me-2 ${loopErr ? 'is-invalid' : ''}`}
                        defaultValue={path ? path.slice(1) : ''}
                    />
                    {loopErr ? <div className="invalid-feedback">This path will create a redirect loop</div> : null}

    

                </div>
                :   
                <span>{path}</span>
                }
            </td>       
            <td>
                {
                    editing ?
                        <span href="" className="btn btn-icon btn-success" onClick={() => updatePath(updatedPath, path, rule)}>
                            <IconDeviceFloppy />

                        </span>
                        :
                    <span href="" className="btn btn-icon" onClick={() => setEditing(!editing)}>
                            <IconEdit />
                    </span>


                }
            </td>
            <td >
                {editing ? 
                <span 
                    className="btn btn-icon" 
                    onClick={() => setEditing(!editing)}
                >
                    <IconX/>
                    
                </span>
                
                 :
                <button 
                    disabled={selectedPaths.length}
                    className="btn btn-icon btn-danger" 
                    onClick={() => handleOpen(path)}>
                    <IconTrash />
                </button>
                }
            </td>
        </tr>
    )
}


export default PathRow
