import React from 'react'

import { IconExternalLink, IconLetterCase, IconFileAlert, IconEdit, IconZoomCheck, IconBinary, IconTrash, IconFlag } from '@tabler/icons'

const RuleInfo = ({ rule, toggleEditMode, toggleDeleteRuleConfirm }) => {

    return (
        <div className="page-body">
        <div className="container-xl">
            <div className="row row-cards">
                <div className="col-md-12">

                    <div className="card">
                        <div className="card-header">
                            <div className="col">

                                <div className="card-subtitle small ">RULE NAME</div>
                                <div className="card-title">{rule.ruleName}</div>

                            </div>
                            <div className="col-auto">
                                <div className="btn-list">
                                    <span className="btn btn-icon" onClick={toggleEditMode}>
                                      <IconEdit />
                                    </span>                 
                                    <span className="btn btn-danger btn-icon" onClick={toggleDeleteRuleConfirm}>
                                        <IconTrash />
                                    </span>

                                </div>
                            </div>
                  
                        </div>
                            <div className="card-body">
                                <div className="row g-3">
                           
                                     <div className="col-9 mb-3">
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <IconExternalLink />
                                            </div>
                                            <div className="col">   
                                                <div className="text-reset d-block.text-truncate small text-muted">
                                                    REDIRECT URL
                                                </div>
                                                <div className="mt-n1">
                                                    <a href={rule.resultredirectURL}>{rule.resultredirectURL}</a>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>   

                                    <div className="col-3 mb-3">
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <IconFlag />
                                            </div>
                                            <div className="col">   
                                                <div className="text-reset d-block.text-truncate small text-muted">
                                                    HOST
                                                </div>
                                                <div className="mt-n1">
                                                    {rule.host || 'n/a' }
                                                </div>
                                            </div>
                                        </div>
                                    </div>                               


                                     <div className="col-3 mb-3">
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <IconFileAlert />
                                            </div>
                                            <div className="col">   
                                                <div className="text-reset d-block.text-truncate small text-muted">
                                                    STATUS CODE
                                                </div>
                                                <div className="mt-n1">
                                                    {rule.resultstatusCode}
                                                </div>
                                            </div>
                                        </div>
                                    </div>         

                                    <div className="col-3 mb-3">
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <IconZoomCheck />
                                            </div>
                                            <div className="col">   
                                                <div className="text-reset d-block.text-truncate text-muted small">
                                                    USE QUERY STRING
                                                </div>
                                                <div className="mt-n1">
                                                    {rule.resultuseIncomingQueryString === '1' ? 'Yes' : 'No'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>                        
                                    
                                     <div className="col-3 mb-3">
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <IconLetterCase />
                                            </div>
                                            <div className="col">   
                                                <div className="text-reset d-block.text-truncate text-muted small">
                                                    
                                                    CASE SENSITIVE
                                                </div>
                                                <div className="mt-n1">
                                                    {rule.caseSensitive ? 'Yes' : 'No'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>                            
                                    
                                     <div className="col-3 mb-3">
                                        <div className="row g-3 align-items-center">
                                            <div className="col-auto">
                                                <IconBinary />
                                            </div>
                                            <div className="col">   
                                                <div className="text-reset d-block.text-truncate small text-muted">
                                                    REGEX
                                                </div>
                                                <div className="mt-n1">
                                                    {rule.rexex || 'n/a' }
                                                </div>
                                            </div>
                                        </div>
                                    </div>                               

                                </div>
                            </div>
                    </div>

    
                </div>             


            </div>

        </div>
    </div>
    )
}


export default RuleInfo